import { HttpResponse, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoaderService } from '../shared/services/loader.service';
import { ToastService } from '../shared/services/toastr.service';

@Injectable()
export class LoaderInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(
        private loaderService: LoaderService,
        private toastService: ToastService
    ) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        this.loaderService.isLoading.next(true);
        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        console.log(err);
                        // if (err.error && err.error.message == undefined) {
                        //     let splitError = err.error.split("\"message\":\"")[1];
                        //     let message = splitError.split(",")[0].substring(0, splitError.split(",")[0].length - 1)
                        //     console.log(message)
                        //     // this.toastService.showErrorToast(message);
                        // }
                        // else
                        //alert(err.status)
                        if (err.status == 555) {
                            this.toastService.warning('Oops! please, try after sometime')
                        }
                        else if (err.error && err.error?.status != 500) {
                            if (err.error.message != undefined && err.error.message != 'No message available') {
                                this.toastService.showErrorToast(err.error.message)
                            }
                        }
                        else {
                            // this.toastService.showErrorToast('Oops! Something went wrong.');
                        }
                        this.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
