import { Component, ElementRef, NgZone, OnInit, ViewChild, } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { CustomerDeliveryAddress } from "src/app/shared/models/customerdeliveryaddress";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { GstSaleInvCumBosDto } from "src/app/shared/models/gstsaleinvcumbosdto";
import { NameWithIdDto } from "src/app/shared/models/namewithiddto";
import { ProductOrderSummary } from "src/app/shared/models/productordersummary";
import { State } from "src/app/shared/models/state";
import { WebOrderMinimumDateTimeSetup } from "src/app/shared/models/weborderminimumdatetimesetup";
import { AddressService } from "src/app/shared/services/address.service";
import { ProductOrderSummaryService } from "src/app/shared/services/product-order-summary.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";
import { GstinDialogComponent } from "../gstin-dialog/gstin-dialog.component";
import * as moment from "moment";
import { StoreService } from "src/app/shared/services/store.service";
import { MapsAPILoader, AgmCoreModule } from "@agm/core";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { getWindow } from "ssr-window";
import { WebOrderLocationSetup } from "src/app/shared/models/weborderlocationsetup";

@Component({
  selector: "app-product-delivery-address",
  templateUrl: "./product-delivery-address.component.html",
  styleUrls: ["./product-delivery-address.component.scss"],
})
export class ProductDeliveryAddressComponent implements OnInit {
  refNo: number;
  customerName: string;
  contactNo: number;
  pincode: number;
  cityCode: number;
  stateId: number;
  districtId: number;
  address: string;
  email: string;
  plot: string;
  locality: string;
  street: string;
  landMark: string;
  latitude: string;
  longitude: string;
  deliveryDate: Date;
  deliveryTime: string;
  stateName: string;
  cityName: string;
  googleCustomer: GoogleCustomer;

  todayDate: Date = new Date();

  webOrderMinimumDateTimeList: WebOrderMinimumDateTimeSetup;

  webUrl: string;
  productOrderSummary: ProductOrderSummary = new ProductOrderSummary();
  gstSaleInvcumBOS: GstSaleInvCumBosDto = new GstSaleInvCumBosDto();
  gstSaleInvcumBOSArray: GstSaleInvCumBosDto[] = [];

  createSetUpForm: UntypedFormGroup;
  stateList: State[] = [];
  districtList: NameWithIdDto[] = [];
  cityList: NameWithIdDto[] = [];
  deliveryDetailsList: any;
  showDeliveryDiv: boolean = false;
  toogle: number;

  companyCode: number;
  currentUrl: string;

  deliveryPinCode: number;
  deliveryLocation: WebOrderLocationSetup;

  //Map
  //For Google Map
  selectedLocationOnMap: any;
  latitudeMap: number;
  longitudeMap: number;
  zoom: number;
  addressMap: string;
  private geoCoder;
  newDeliveryDetails: boolean = true;

  @ViewChild("map", { static: true }) map: any;
  @ViewChild("search")
  public searchElementRef: ElementRef;

  private stateSubcription: Subscription;
  private districtListSubscription: Subscription;
  private cityListSubscription: Subscription;
  private webOrderMinimumDateTimeSubcription: Subscription;
  private customerDeliveryDetailsSubcription: Subscription;
  private getDeliveryDetailsSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private formBuilder: UntypedFormBuilder,
    private addressService: AddressService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private productService: ProductService,
    private navRoute: Router,
    private storeService: StoreService,
    private productOrderSummaryService: ProductOrderSummaryService,
    public dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname;
    this.onCreateSetUp();
    this.companyCode = JSON.parse(this.localStorage.getItem("company_code"));
    this.webUrl = environment.cartApiUrl;
    this.deliveryPinCode = JSON.parse(this.localStorage.getItem("pincode_item"));
    this.deliveryLocation = JSON.parse(this.localStorage.getItem("delivery_location"));
    console.log(this.deliveryLocation);
    this.getProductOrderSummaryDetails();
    this.getStateList();
    this.getWebOrders();
    this.getWebOrdersArray();
    // Step 1: Load stored delivery data
    const stored = this.localStorage.getDeliveryDetails();
    console.log("Stored delivery details:", stored);
    if (stored) {
      this.createSetUpForm.patchValue({
        pincode: stored.pincode,
        latitude: stored.latitude,
        longitude: stored.longitude,
      });

      this.latitudeMap = stored.latitude;
      this.longitudeMap = stored.longitude;
      this.deliveryPinCode = stored.pincode;
    }

    // Step 2: Load map
    this.getGoogleMap();

    // Step 3: Load delivery addresses
    this.getDeliveryDetails();
  }

  getMinimumTimeToOrder() {
    this.webOrderMinimumDateTimeSubcription = this.addressService.getWebOrderMinimumDateTimeDetails(this.currentUrl).subscribe((data) => {
      this.webOrderMinimumDateTimeList = data;
    });
  }
  disableDate: boolean = false;
  disableTime: boolean = false;

  getProductOrderSummaryDetails() {
    this.productOrderSummary = this.productOrderSummaryService.getOrderSummary();
    console.log(this.productOrderSummary);
    if (this.productOrderSummary == undefined) {
      this.navRoute.navigate(["/cart-items"]);
    }
    // var deliveryDateTime: any = JSON.parse(this.localStorage.getItem("delivery_date"));
    // var mtoOrder: any = JSON.parse(this.localStorage.getItem("mto_order"));

    // console.log(deliveryDateTime)
    // console.log(moment(deliveryDateTime).format("hh:mm a"))

    // if (mtoOrder) {
    //   this.disableDate = true;
    //   this.disableTime = true;
    // }
    // else {
    //   this.disableDate = false;
    //   this.disableTime = false;
    // }

    // this.createSetUpForm.patchValue({
    //   deliveryDate: moment(deliveryDateTime).format("YYYY-MM-DD"),
    //   deliveryTime: moment(deliveryDateTime).format("hh:mm a"),
    // });
  }

  getWebOrders() {
    this.gstSaleInvcumBOS = this.productOrderSummaryService.getWebOrder();
    console.log(this.gstSaleInvcumBOS);
    // if (this.gstSaleInvcumBOS.details.length != 0) {
    //   this.deliveryDate = new Date(this.gstSaleInvcumBOS.details[0].deliveryDate);
    //   this.deliveryTime = this.gstSaleInvcumBOS.details[0].deliveryTime;
    // }
  }

  getWebOrdersArray() {
    this.gstSaleInvcumBOSArray = this.productOrderSummaryService.getWebOrderArray();
    console.log(this.gstSaleInvcumBOSArray);
    // if (this.gstSaleInvcumBOSArray != undefined) {
    //   this.deliveryDate = new Date(this.gstSaleInvcumBOS.details[0].deliveryDate);
    //   this.deliveryTime = this.gstSaleInvcumBOS.details[0].deliveryTime;
    // }
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      id: [""],
      customerName: ["", Validators.required],
      contactNo: ["", [Validators.required, Validators.pattern("[6-9]\\d{9}")]],
      email: [""],
      // email: ["", Validators.compose([Validators.required,Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),]),],
      deliveryDate: [""],
      deliveryTime: [""],
      plotNo: ["", Validators.required],
      street: ["", Validators.required],
      landMark: [""],
      pincode: ["", [Validators.required, Validators.pattern("^[1-9][0-9]{5}$")],],
      cityCode: ["", Validators.required],
      stateCode: ["", Validators.required],
      districtCode: ["", Validators.required],
      address: ["", Validators.required],
      logInCustomerEmail: [""],
      defaultAddress: [""],
      latitude: ["", Validators.required],
      longitude: ["", Validators.required],
    });
  }

  getStateList() {
    this.stateSubcription = this.addressService.getStateList().subscribe((data) => {
      this.stateList = data;
    });
  }

  onStateSelect(stateCode: number) {
    this.districtListSubscription = this.addressService.getDistrictByStateCode(this.apiFactory.getDistrictListByStateCode, stateCode).subscribe((data) => {
      this.districtList = data;
    });
  }

  onDistrictSelect(districtCode: number) {
    this.cityListSubscription = this.storeService.getCitysByDistrictCode(this.apiFactory.getCityListByDistrictCode, districtCode).subscribe((data) => {
      this.cityList = data;
    });
  }

  getDeliveryDetails() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.createSetUpForm.get('pincode').setValue(this.deliveryPinCode);

    const stored = this.localStorage.getDeliveryDetails(); // 🔁 get again here

    if (this.googleCustomer?.email != null) {
      this.getDeliveryDetailsSubscription = this.addressService
        .getDeliveryDetails(this.apiFactory.getDeliveryDetailsByCustomerCode, this.googleCustomer.refNo, this.deliveryPinCode.toString())
        .subscribe((data) => {
          if (data.length > 0) {
            this.deliveryDetailsList = data;
            this.showDeliveryDiv = true;

            if (stored) {
              console.log("Stored Delivery Details:", stored);

              data.forEach((item, index) => {
                console.log(`Checking item at index ${index}`);
                console.log('Item.latitude:', Number(item.latitude), 'Stored.latitude:', Number(stored.latitude));
                console.log('Item.longitude:', Number(item.longitude), 'Stored.longitude:', Number(stored.longitude));
                console.log('Item.shippingPinCode:', Number(item.shippingPinCode), 'Stored.pincode:', Number(stored.pincode));

                const latMatch = Number(item.latitude) === Number(stored.latitude);
                const lngMatch = Number(item.longitude) === Number(stored.longitude);
                const pinMatch = Number(item.shippingPinCode) === Number(stored.pincode);

                console.log(`Match status -> lat: ${latMatch}, lng: ${lngMatch}, pin: ${pinMatch}`);
              });

              const matchedIndex = data.findIndex(item =>
                Number(item.latitude) === Number(stored.latitude) &&
                Number(item.longitude) === Number(stored.longitude) &&
                Number(item.shippingPinCode) === Number(stored.pincode)
              );

              console.log("Matched Index:", matchedIndex);

              if (matchedIndex !== -1) {
                this.onDeliveryDetailsClick(data[matchedIndex], matchedIndex);
              }
            }


          } else {
            this.showDeliveryDiv = false;
          }
        });
    }
  }


  onDeliveryDetailsClick(deliveryDetail: any, index: number) {
    console.log(deliveryDetail);
    if (index == this.toogle) {
      this.toogle = null;
      this.createSetUpForm.reset();
      this.createSetUpForm.get('pincode').setValue(this.deliveryPinCode);
    } else {
      this.toogle = index;
      // var deliveryDateTime: any = JSON.parse(this.localStorage.getItem("delivery_date"));
      this.onStateSelect(deliveryDetail.shippingCity?.district?.state?.refNo);
      this.onDistrictSelect(deliveryDetail.shippingCity?.district?.refNo);
      this.createSetUpForm.patchValue({
        id: deliveryDetail.refNo,
        customerName: deliveryDetail.contactName,
        contactNo: deliveryDetail.contactMobile,
        email: this.googleCustomer.email,
        // deliveryDate: moment(deliveryDateTime).format("YYYY-MM-DD"),
        // deliveryTime: moment(deliveryDateTime).format("hh:mm a"),
        plotNo: deliveryDetail.shippingPlotNo,
        areaLocality: deliveryDetail.areaLocality,
        pincode: deliveryDetail.shippingPinCode,
        cityCode: deliveryDetail.shippingCityCode,
        stateCode: deliveryDetail.shippingCity?.district?.state?.refNo,
        districtCode: deliveryDetail.shippingCity?.district?.refNo,
        address: deliveryDetail.shippingAddress,
        street: deliveryDetail.shippingStreet,
        landMark: deliveryDetail.shippingLandMark,
        latitude: deliveryDetail.latitude,
        longitude: deliveryDetail.longitude,
        logInCustomerEmail: this.googleCustomer.email,
        defaultAddress: deliveryDetail.defaultAddress,
      });
      this.latitudeMap = deliveryDetail.latitude;
      this.longitudeMap = deliveryDetail.longitude;
      this.zoom = 2;
      this.getAddress(Number(this.latitudeMap), Number(this.longitudeMap));
    }
  }

  changeColor(index: number) {
    this.toogle = index;
  }

  onCreateSetUpClick() {
    console.log(this.createSetUpForm);
    if (this.createSetUpForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }
    if (this.createSetUpForm.get("pincode").value != this.deliveryPinCode) {
      this.toastService.showErrorToast('Delivery available to this pincode ' + this.deliveryPinCode);
      return;
    }

    this.refNo = this.createSetUpForm.get("id").value;
    this.customerName = this.createSetUpForm.get("customerName").value;
    this.contactNo = this.createSetUpForm.get("contactNo").value;
    this.pincode = this.createSetUpForm.get("pincode").value;
    this.cityCode = this.createSetUpForm.get("cityCode").value;
    this.stateId = this.createSetUpForm.get("stateCode").value;
    this.districtId = this.createSetUpForm.get("districtCode").value;
    this.address = this.createSetUpForm.get("address").value;
    this.email = this.createSetUpForm.get("email").value;
    this.plot = this.createSetUpForm.get("plotNo").value;
    this.street = this.createSetUpForm.get("street").value;
    this.landMark = this.createSetUpForm.get("landMark").value;
    this.latitude = this.createSetUpForm.get("latitude").value;
    this.longitude = this.createSetUpForm.get("longitude").value;
    this.stateName = this.stateList.filter(e => e.refNo == this.stateId)[0]?.stateName;
    this.cityName = this.cityList.filter(e => e.id == this.cityCode)[0]?.name;

    //For indivisual order
    if (this.gstSaleInvcumBOS && this.gstSaleInvcumBOS.details.length != 0) {
      this.gstSaleInvcumBOS.details.forEach((d) => {
        this.deliveryDate = new Date(d.deliveryDate);
        this.deliveryTime = d.deliveryTime;
      });
      console.log(this.deliveryTime);

      this.deliveryDate.setHours(Number.parseInt(this.deliveryTime.split(":")[0]));
      this.deliveryDate.setMinutes(Number.parseInt(this.deliveryTime.split(":")[1]));

      var deliveryAddress: CustomerDeliveryAddress = new CustomerDeliveryAddress();

      deliveryAddress.refNo = this.refNo;
      deliveryAddress.contactName = this.customerName;
      deliveryAddress.contactMobile = this.contactNo;
      deliveryAddress.shippingPinCode = this.pincode;
      deliveryAddress.shippingCityCode = this.cityCode;
      deliveryAddress.shippingAddress = this.address;
      deliveryAddress.shippingPlotNo = this.plot;
      deliveryAddress.shippingLandMark = this.landMark;
      deliveryAddress.shippingStreet = this.street;
      deliveryAddress.email = this.email;
      deliveryAddress.stateCode = this.stateId;
      deliveryAddress.districtCode = this.districtId;
      deliveryAddress.deliveryDate = this.deliveryDate;
      deliveryAddress.latitude = this.latitude;
      deliveryAddress.longitude = this.longitude;
      deliveryAddress.stateName = this.stateName;
      deliveryAddress.cityName = this.cityName;
      deliveryAddress.customerCode = this.googleCustomer.refNo;

      this.productOrderSummaryService.setCustomerDeliveryAddress(deliveryAddress);
      this.gstSaleInvcumBOS.customerDeliveryAddress = deliveryAddress;
      console.log(this.gstSaleInvcumBOS);
      this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
      this.productOrderSummary.deliveryAddress = deliveryAddress;
      this.productOrderSummaryService.setOrderSummary(this.productOrderSummary);
    }

    //For total order
    if (this.gstSaleInvcumBOSArray && this.gstSaleInvcumBOSArray.length != 0) {
      this.gstSaleInvcumBOSArray.forEach((e) => {
        e.details.forEach((d) => {
          this.deliveryDate = new Date(d.deliveryDate);
          this.deliveryTime = d.deliveryTime;
        });
        var deliveryAddress: CustomerDeliveryAddress = new CustomerDeliveryAddress();

        deliveryAddress.refNo = this.refNo;
        deliveryAddress.contactName = this.customerName;
        deliveryAddress.contactMobile = this.contactNo;
        deliveryAddress.shippingPinCode = this.pincode;
        deliveryAddress.shippingCityCode = this.cityCode;
        deliveryAddress.shippingAddress = this.address;
        deliveryAddress.shippingPlotNo = this.plot;
        deliveryAddress.shippingLandMark = this.landMark;
        deliveryAddress.shippingStreet = this.street;
        deliveryAddress.stateCode = this.stateId;
        deliveryAddress.districtCode = this.districtId;
        deliveryAddress.email = this.email;
        deliveryAddress.deliveryDate = this.deliveryDate;
        deliveryAddress.latitude = this.latitude;
        deliveryAddress.longitude = this.longitude;
        deliveryAddress.stateName = this.stateName;
        deliveryAddress.cityName = this.cityName;
        deliveryAddress.customerCode = this.googleCustomer.refNo;

        e.customerDeliveryAddress = deliveryAddress;
      });
      console.log(this.gstSaleInvcumBOSArray);
      this.productOrderSummaryService.setWebOrderArray(this.gstSaleInvcumBOSArray);

      this.productOrderSummary.deliveryAddress = deliveryAddress;
      this.productOrderSummaryService.setOrderSummary(this.productOrderSummary);
    }

    this.createSetUpForm.patchValue({ logInCustomerEmail: this.googleCustomer.email, });
    console.log(this.createSetUpForm.value);
    this.customerDeliveryDetailsSubcription = this.addressService.insertDeliveryDetails(this.apiFactory.insertDeliveryDetailsApp, this.productOrderSummary.deliveryAddress)
      .subscribe((data) => {
        console.log(data);
        this.productOrderSummary.deliveryAddressRefNo = data.refNo;
        this.productOrderSummaryService.setOrderSummary(this.productOrderSummary);
        this.localStorage.setItem("last_delivery_address", JSON.stringify(this.productOrderSummary.deliveryAddress));
        this.toastService.showSuccessToast("Saved Successfully");
        this.navRoute.navigate(["/order-summary"]);
      });

    // this.dialog.open(GstinDialogComponent, {
    //   width: "350px",
    //   autoFocus: false,
    // });

    // this.webOrderMinimumDateTimeSubcription = this.addressService.getWebOrderMinimumDateTimeDetails().subscribe((data) => {
    //   this.webOrderMinimumDateTimeList = data;

    //   //function for checking deleivery duartion
    //   this.deliveryDate.setHours(
    //     Number.parseInt(this.deliveryTime.split(":")[0])
    //   );
    //   this.deliveryDate.setMinutes(
    //     Number.parseInt(this.deliveryTime.split(":")[1])
    //   );

    //   var orderDuration = new Date();
    //   orderDuration.setHours(orderDuration.getHours() + Number.parseInt(
    //     this.webOrderMinimumDateTimeList.deliveryTime.split(":")[0]));
    //   orderDuration.setMinutes(orderDuration.getMinutes() + Number.parseInt(
    //     this.webOrderMinimumDateTimeList.deliveryTime.split(":")[1]));

    //   if (orderDuration.getTime() >= this.deliveryDate.getTime()) {
    //     this.toastService.showErrorToast("Cannot place order please select Date After " +
    //       this.webOrderMinimumDateTimeList.deliveryTime.split(":")[0] + " " + "Hour");
    //   } else {
    //     var deliveryAddress: CustomerDeliveryAddress = new CustomerDeliveryAddress();
    //     deliveryAddress.customerName = this.customerName;
    //     deliveryAddress.mobileNo = this.contactNo;
    //     deliveryAddress.pinCode = this.pincode;
    //     deliveryAddress.city = this.cityName;
    //     deliveryAddress.districtCode = this.districtId;
    //     deliveryAddress.houseAddress = this.address;
    //     deliveryAddress.email = this.email;
    //     deliveryAddress.deliveryDate = this.deliveryDate;
    //     console.log(this.deliveryTime)
    //     if (this.deliveryTime.split(" ")[1] == 'pm') {
    //       deliveryAddress.deliveryDate.setHours(
    //         Number.parseInt(this.deliveryTime.split(":")[0]) + 12);
    //     }
    //     else {
    //       deliveryAddress.deliveryDate.setHours(
    //         Number.parseInt(this.deliveryTime.split(":")[0])
    //       );
    //     }
    //     deliveryAddress.deliveryDate.setMinutes(
    //       Number.parseInt(this.deliveryTime.split(":")[1])
    //     );

    //     console.log(deliveryAddress)
    //     deliveryAddress.plotNo = this.plot;
    //     deliveryAddress.areaLocality = this.locality;
    //     deliveryAddress.stateCode = this.stateId;
    //     this.gstSaleInvcumBOS.customerDeliveryAddress = deliveryAddress;
    //     this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);

    //     var deliveryAddress: CustomerDeliveryAddress = new CustomerDeliveryAddress();
    //     deliveryAddress.customerName = this.customerName;
    //     deliveryAddress.mobileNo = this.contactNo;
    //     deliveryAddress.pinCode = this.pincode;
    //     deliveryAddress.city = this.cityName;
    //     deliveryAddress.districtCode = this.districtId;
    //     deliveryAddress.houseAddress = this.address;
    //     deliveryAddress.email = this.email;
    //     deliveryAddress.plotNo = this.plot;
    //     deliveryAddress.areaLocality = this.locality;
    //     deliveryAddress.deliveryDate = this.deliveryDate;
    //     this.productOrderSummary.deliveryAddress = deliveryAddress;

    //     this.productOrderSummaryService.setOrderSummary(this.productOrderSummary);

    //     this.createSetUpForm.patchValue({
    //       logInCustomerEmail: this.googleCustomer.email
    //     })
    //     console.log(this.createSetUpForm.value)
    //     this.customerDeliveryDetailsSubcription = this.addressService.insertDeliveryDetails(this.apiFactory.insertDeliveryDetails, this.createSetUpForm).subscribe((data) => {
    //       this.toastService.showSuccessToast("Saved Successfully");
    //     });
    //     this.dialog.open(GstinDialogComponent, {
    //       width: "350px",
    //     });
    //   }
    // });
  }

  choosePincode() {
    let pincode = this.createSetUpForm.get("pincode").value;
    if (pincode.toString().length === 6) {
      this.validatePinCode(pincode);
    }
  }

  validatePinCode(pin: number) {
    if (this.deliveryPinCode) {
      if (pin == this.deliveryPinCode) {
        this.createSetUpForm.get("latitude").reset();
        this.createSetUpForm.get("longitude").reset();
      } else {
        this.toastService.showErrorToast('Delivery available to this pincode ' + this.deliveryPinCode);
        return;
      }
    } else {
      this.toastService.showErrorToast('Give Delivery Pincode');
    }
  }

  //Map
  userSelectedLocation = false;
  getGoogleMap() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();

      // If user hasn't selected a location, load initial one (e.g., from PinDialog)
      if (!this.userSelectedLocation && this.latitudeMap && this.longitudeMap) {
        this.getAddress(this.latitudeMap, this.longitudeMap);
      }

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (!place.geometry) return;

          this.userSelectedLocation = true; // ✅ mark that user picked a location

          this.latitudeMap = place.geometry.location.lat();
          this.longitudeMap = place.geometry.location.lng();
          this.zoom = 12;

          this.getAddress(this.latitudeMap, this.longitudeMap);

          this.createSetUpForm.patchValue({
            latitude: this.latitudeMap,
            longitude: this.longitudeMap
          });
        });
      });
    });
  }


  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 10;
        this.getAddress(this.latitudeMap, this.longitudeMap);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.toastService.clearAllToast();
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.zoom = 12;
          this.addressMap = results[0].formatted_address;

          const addressComponents = results[0].address_components;
          let pincode = '';

          // Check if pincode is present
          const hasPincode = addressComponents.some(comp => comp.types.includes('postal_code'));
          if (!hasPincode) {
            this.resetMapFields('Your location does not have a valid pincode.');
            return;
          }

          // Extract Pincode
          for (let component of addressComponents) {
            if (component.types.includes('postal_code')) {
              pincode = component.long_name;
              console.log('deliveryPinCode:', this.deliveryPinCode);
              console.log('Extracted pincode:', pincode);
              if (String(pincode) !== String(this.deliveryPinCode)) {
                this.resetMapFields('Your location does not match the PIN: ' + this.deliveryPinCode);
                return;
              }
              break;
            }
          }
        } else {
          this.toastService.showErrorToast("No results found");
        }
      } else {
        this.toastService.showErrorToast("Geocoder failed due to: " + status);
      }
    });
  }

  resetMapFields(message: string) {
    this.createSetUpForm.get("latitude").reset();
    this.createSetUpForm.get("longitude").reset();
    this.toastService.showErrorToast(message);
  }



  markerDragEnd(event: any) {
    this.latitudeMap = event.latLng.lat();
    this.longitudeMap = event.latLng.lng();
    this.getAddress(this.latitudeMap, this.longitudeMap);
    this.createSetUpForm.patchValue({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    });
  }

  fetchCurrentLoc1() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 12;
        this.createSetUpForm.patchValue({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        this.getAddress(this.latitudeMap, this.longitudeMap);
      });
    }
  }

  fetchCurrentLoc() {
    if (!("geolocation" in navigator)) {
      this.toastService.showErrorToast("Geolocation not supported");
      return;
    }

    this.toastService.showWarningToast("Fetching current location...");

    let watchId = navigator.geolocation.watchPosition(
      (position) => {
        console.log("Live location fetched: ", position);

        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 12;

        this.createSetUpForm.patchValue({
          latitude: this.latitudeMap,
          longitude: this.longitudeMap,
        });

        this.getAddress(this.latitudeMap, this.longitudeMap);

        // Stop watching after first successful fetch
        navigator.geolocation.clearWatch(watchId);
      },
      (error) => {
        console.error("Geolocation error: ", error);
        let message = "";

        switch (error.code) {
          case error.PERMISSION_DENIED:
            message = "Permission denied. Enable location.";
            break;
          case error.POSITION_UNAVAILABLE:
            message = "Location unavailable. Try again.";
            break;
          case error.TIMEOUT:
            message = "Request timed out.";
            break;
          default:
            message = "Unknown location error.";
        }

        this.toastService.showErrorToast(message);
        navigator.geolocation.clearWatch(watchId);
      },
      {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 0
      }
    );
  }
}
